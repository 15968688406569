@use "src/styles/variables" as variables;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;600;700&display=swap");

html,
body {
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: variables.$themeColorDarkGrey;
  text-decoration: none;
  opacity: 0.85;
  transition: all 0.28s ease-in;
  &:hover,
  &:focus {
    opacity: 1;
  }
}

.loading {
  padding: 0.5rem 1rem;
}

.contentContainer {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.MAL__salesLead {
  background: #a34ea3;
  padding: 1px 2px;
  border-radius: 2px;
  color: white;
  font-size: 10px;
  vertical-align: middle;
  margin-left: 4px;
}
