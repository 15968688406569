/* Colors */
$themeColorPrimary: #e1b76c;
$themeColorPrimaryLighter: #ffbd54;
$themeColorPrimaryDarker: #db9c37;

$themeColorSecondary: #ea302a;
$themeColorSecondaryDarker: #cc1d17;

$themeColorBlack: #282828;

$themeColorGrey: #e4e9f0;
$themeColorLightGrey: #f2f4f8;
$themeColorDarkGrey: #636262;

$themeColorRed: #e74c3c;
$themeColorSuccess: #41b884;

$drawerWidth: 230px;
$spacing: 8px;

$fileInputSize: 125px;
$fileInputSizeSmall: 80px;

/* Default MUI's breakpoints https://material-ui.com/customization/breakpoints/ */
$screen-sm-min: 600px;
$screen-md-min: 960px;
$screen-lg-min: 1280px;
$screen-xl-min: 1920px;

@mixin xs {
  @media (max-width: #{$screen-sm-min - 1}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

.flexRow {
  flex-direction: column;
  margin: 0 auto;
  padding: 0 24px;

  @include xs {
    width: calc(100vw - 1.5rem);
  }

  @include sm {
    width: calc(100vw - 2rem);
  }

  @include md {
    width: calc(100vw - $drawerWidth - 6rem);
  }

  @include lg {
    width: $screen-lg-min - $drawerWidth - 20;
  }

  @include xl {
    width: $screen-xl-min - $drawerWidth - 40;
  }
}
