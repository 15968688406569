.container {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title,
.desc {
  margin-bottom: 8px;
}

.title {
  font-size: 22px;
  font-weight: bold;
  line-height: 27px;
}

.desc {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
