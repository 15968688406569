@use "src/styles/variables" as variables;

.contained {
  background: variables.$themeColorPrimary;
  color: white;
  &:hover {
    background: variables.$themeColorPrimaryLighter;
  }
}

.outlinedSelected {
  color: variables.$themeColorPrimary;
  border-color: variables.$themeColorPrimary;
  background-color: none;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;

  svg {
    fill: variables.$themeColorPrimary;
    color: variables.$themeColorPrimary;
  }
}
